import React, { useState, useEffect } from "react";
import Dashboard from "../Dashboard";
import Flip from "react-reveal/Flip";
import TopEmployeeForm from "../homeComponents/TopEmployeeForm";
import TopServiceForm from "../homeComponents/TopServiceForm";
import AppointmentList from "../homeComponents/AppointmentList";
import AppointmentSummary from "../homeComponents/AppointmentSummary";
import AppointmentStatus from "../homeComponents/AppointmentStatus";
import Graph from "../homeComponents/Graph";
import { axiosRequest } from "../../api";
import { getUser } from "../utils/common";
import { useMediaQuery } from "@mui/material";

const Appointment_URL = "appointment";
const CarAppointment_URL = "carappointment";
const AppointPending_URL = "appointment/pending";
const AppointCompleted_URL = "appointment/completed";
const AppointCancelled_URL = "appointment/cancelled";
const CarAppointPending_URL = "carappointment/pending";
const CarAppointCompleted_URL = "carappointment/completed";
const CarAppointCancelled_URL = "carappointment/cancelled";
const Sales_URL = "sales";
const Services_URL = "newWay/cart/topServ";

const Emp_URL = "newWay/cart/topEmp";

const Home = () => {
  const [mAppointment, setMAppointment] = useState(false);
  const [tAppointment, setTAppointment] = useState(true);
  const [topServiceData, setTopServiceData] = useState([]);
  const [topEmpData, setTopEmpData] = useState([]);
  const [Data, setData] = useState([]);
  const [carData, setCarData] = useState([]);

  const [pending, setPending] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [carPending, setCarPending] = useState([]);
  const [carCompleted, setCarCompleted] = useState([]);
  const [carCancelled, setCarCancelled] = useState([]);
  

  const isTablet = useMediaQuery("(max-width: 960px)");

  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));
  const changeDateFormat = () => {
    const date = new Date();
    
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed in JavaScript
    const day = date.getDate().toString().padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate);
    return  formattedDate; // Outputs: 2024-04-02
    
  }
  const handlerTAppointment = () => {
    setTAppointment(true);
    setMAppointment(false);
  };

  const handlerMAppointment = () => {
    setTAppointment(false);
    setMAppointment(true);
  };

  const chartData = [];
  salesData.forEach((item) => {
    chartData.push({
      amount: item.total,
      date: item.createdAt.split("T")[0],
      id: item._id,
    });
  });

  const getSales = () => {
    axiosRequest
      .get(Sales_URL)
      .then((res) => {
        const response = res.data.data;
        const result = response.filter(
          (element) => element.status === "COMPLETED"
        );
        setSalesData(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getTopEmployees = () => {
    axiosRequest
      .get(Emp_URL+'?status=Completed&date='+changeDateFormat())
      .then((res) => {
        const response = res.data.data;
        
        setTopEmpData(response);
        console.log("emp--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getTopService = () => {
    axiosRequest
      .get(Services_URL+'?status=Completed&date='+changeDateFormat())
      .then((res) => {
        const response = res.data.data;
       
        setTopServiceData(response);
        console.log("serv--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getAppointment = () => {
    axiosRequest
      .get(Appointment_URL)
      .then((response) => {
        const result = response.data.data;
        setData(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getCarAppointment = () => {
    axiosRequest
      .get(CarAppointment_URL)
      .then((response) => {
        const result = response.data.data;
        setCarData(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getAppointCompleted = () => {
    axiosRequest
      .get(AppointCompleted_URL)
      .then((response) => {
        const result = response.data;
        setCompleted(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getAppointPending = () => {
    axiosRequest
      .get(AppointPending_URL)
      .then((response) => {
        const result = response.data;
        setPending(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getAppointCancelled = () => {
    axiosRequest
      .get(AppointCancelled_URL)
      .then((response) => {
        const result = response.data;
        setCancelled(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getCarAppointCompleted = () => {
    axiosRequest
      .get(CarAppointCompleted_URL)
      .then((response) => {
        const result = response.data;
        setCarCompleted(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getCarAppointPending = () => {
    axiosRequest
      .get(CarAppointPending_URL)
      .then((response) => {
        const result = response.data;
        setCarPending(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getCarAppointCancelled = () => {
    axiosRequest
      .get(CarAppointCancelled_URL)
      .then((response) => {
        const result = response.data;
        setCarCancelled(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    getAppointment();
    getCarAppointment();
    getAppointCompleted();
    getAppointPending();
    getAppointCancelled();
    getCarAppointCompleted();
    getCarAppointPending();
    getCarAppointCancelled();
    getSales();
    getTopService();
    getTopEmployees();

  }, []);

  return (
    <Dashboard>
      <div className={`content-body mt-[2rem] ${!isTablet && "ml-52"}`}>
        <div className="container-fluid">
        {tAppointment  && (
                  <AppointmentStatus
                    onclick={handlerTAppointment}
                    onclicked={handlerMAppointment}
                    appointLength={Data.length}
                    carappointLength={carData.length}
                    completed={completed.Length}
                    pending={pending.Length}
                    cancelled={cancelled.Length}
                    carcompleted={carCompleted.Length}
                    carpending={carPending.Length}
                    carcancelled={carCancelled.Length}
                    completedPercent={completed.percentage}
                    pendingPercent={pending.percentage}
                    cancelledPercent={cancelled.percentage}
                  />
                )}
          <div className="row mt-8">
         <Graph chartData={chartData} isTablet={isTablet} />
          </div>
          <div className="row">
            <TopEmployeeForm salesData={topEmpData} isTablet={isTablet} />
            <TopServiceForm salesData={topServiceData} isTablet={isTablet} />
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default Home;
