/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";
import Dashboard from "../../Dashboard";
import "../sales/index.css";
import Fade from "react-reveal/Fade";
import { axiosRequest, refreshPage } from "../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "../../Skeleton";
import LoadingButton from "../../LoadingButton";
import { getUser } from "../../utils/common";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FaFileExport } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useMediaQuery } from "@mui/material";
import User from '../../../assets/images/undraw_photo_re_5blb.svg';
import Axios from 'axios'

const Service_URL = "car";
const Category_URL = "carCategory";

const Car = () => {
  const [createNewDataModel, setCreateNewDataModel] = useState(false);
  const [deleteDataModel, setDeleteDataModel] = useState(false);
  const [addNewCategoryModel, setAddNewCategoryModel] = useState(false);
  const [updateDataModel, setUpdateDataModel] = useState(false);
  const [RowData, SetRowData] = useState([]);
  const [Data, setData] = useState([]);
  const [id, setId] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [Category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentpage] = useState(1);
  const [postsPerPage] = useState(10);
  const isTablet = useMediaQuery("(max-width: 960px)");

  const [open, setOpen] = useState(false);
  const handleDropDown = () => setOpen(!open);

  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));

  const [formData, setFormData] = useState({
    carname: "",
    category_id: "",
    service_description: "",
    aftercare_description: "",
    service_gender: "",
    name: "",
    duration: "1440",
    amount: "",
    seats:"",
    fuel:"",
    transmission:"",
    booking_status: "",
    commission_status: true,
    status: "",
    images:[],
    imageData: []
  });

  const [catFormData, setCatFormData] = useState({
    categoryname: "",
    category_Description: "",
  });

  const removeModel = () => {
    let newState = !createNewDataModel;
    setCreateNewDataModel(newState);
  };

  const categoryModel = () => {
    let newState = !addNewCategoryModel;
    setAddNewCategoryModel(newState);
  };

  const deleteModel = () => {
    let newState = !deleteDataModel;
    setDeleteDataModel(newState);
  };

  const updateModel = () => {
    let newState = !updateDataModel;
    setUpdateDataModel(newState);
  };

  const getCars = () => {
    setLoading(true);
    axiosRequest
      .get(Service_URL)
      .then((response) => {
        setLoading(false);
        const result = response.data.data;
        setData(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getCarCategories = () => {
    axiosRequest
      .get(Category_URL)
      .then((response) => {
        const result = response.data.data;
        setCategory(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
   const uploadImages = async() => {
    let secureUrls = [];
    await Promise.all(
    formData.imageData.map(async(iimage,i)=>{
      const formDataa = new FormData()
  
      formDataa.append("file", iimage.binary)
      formDataa.append("upload_preset", "znd6fmzl")
      setLoading(true)
      
      const response = await Axios.post("https://api.cloudinary.com/v1_1/deh1jmcjy/image/upload", formDataa);
        // setImage(response.data.secure_url)
        console.log("res.......",response.data.secure_url)
        const portiflioImage = response.data.secure_url
       
        secureUrls.push(portiflioImage);
   
      
    })
  )
    return secureUrls;
    

   }
  


  const handlerSubmit = async (e) => {
    e.preventDefault();
    const url = "car";
    
    let errors = {};
    setLoading(true);
    setFormErrors(errors);
    
    if (Object.keys(errors).length === 0) {
      const url = "car";
      setLoading(true);
     
      if (formData.imageData) {
       
        const images = await uploadImages();

        console.log("responseeeeeee.......",images)
        // setFormData({...formData,images: secureUrls })
        await axiosRequest
        .post(url,{...formData,images:images})
        .then((res) => {
          setFormData({
            carname: "",
            category_id: "",
            service_description: "",
            aftercare_description: "",
            service_gender: "",
            name: "",
            duration: "1440",
            amount: "",
            seats:"",
            fuel:"",
            transmission:"",
            booking_status: "",
            commission_status: true,
            status: "",
            images:[],
            imageData:[]
          });
          setLoading(false);
          const result = res.data;
          const { message } = result;
          toast.success(message);
          setCreateNewDataModel(false);
          setTimeout(() => {
            refreshPage();
            getCars();
          }, 1000);
        })
        .catch((error) => {
          toast.info(error.message);
          setTimeout(() => {
            setLoading(false);
            refreshPage();
          }, 2000);
        });
      }
     
    }
    
    
  };

  const handlerCatSubmit = async (e) => {
    e.preventDefault();
    const url = "carCategory";
    setLoading(true);
    await axiosRequest
      .post(url, catFormData)
      .then((res) => {
        setCatFormData({
          categoryname: "",
          category_Description: "",
        });
        setLoading(false);
        const result = res.data;
        const { message } = result;
        getCars();
        getCarCategories();
        setAddNewCategoryModel(false);
        toast.success(message);
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          toast.error(error.response.data.message);
        } else {
          toast.info(error.message);
          setTimeout(() => {
            setLoading(false);
            refreshPage();
          }, 2000);
        }
      });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const url = `car/${id}`;
    setLoading(true);
    axiosRequest
      .put(url, { isDeleted: "true" })
      .then((response) => {
        setLoading(false);
        getCars();
        setDeleteDataModel(false);
        toast.success("Deleted succefully");
      })
      .catch((error) => {
        toast.info(error.message);
        setTimeout(() => {
          setLoading(false);
          refreshPage();
        }, 2000);
      });
  };

  const handleUpdate = async(e) => {
    e.preventDefault();
    let errors = {};
    // setLoading(true);
    setFormErrors(errors);
    
    if (Object.keys(errors).length === 0) {
      // const url = "car";
      setLoading(true);
     
      if (formData.imageData) {
       
    const images = await uploadImages();
    const url = `car/${id}`;
    setLoading(true);
    axiosRequest
      .put(url, {...formData,images:images})
      .then((response) => {
        setFormData({
          carname: "",
          category_id: "",
          service_description: "",
          aftercare_description: "",
          service_gender: "",
          name: "",
          duration: "1440",
          amount: "",
          seats:"",
          fuel:"",
          transmission:"",
          booking_status: "",
          commission_status: true,
          status: "",
          images:[],
          imageData:[]
        });
        setLoading(false);
        const result = response.data;
        const { message } = result;
        getCars();
        toast.success(message);
        setUpdateDataModel(false);
      })
      .catch((error) => {
        toast.info(error.message);
        setTimeout(() => {
          setLoading(false);
          refreshPage();
        }, 2000);
      });
      }else{
        const url = `car/${id}`;
    setLoading(true);
    axiosRequest
      .put(url, formData)
      .then((response) => {
        setFormData({
          carname: "",
          category_id: "",
          service_description: "",
          aftercare_description: "",
          service_gender: "",
          name: "",
          duration: "1440",
          amount: "",
          seats:"",
          fuel:"",
          transmission:"",
          booking_status: "",
          commission_status: true,
          status: "",
          images:[],
          imageData:[]
        });
        setLoading(false);
        const result = response.data;
        const { message } = result;
        getCars();
        toast.success(message);
        setUpdateDataModel(false);
      })
      .catch((error) => {
        toast.info(error.message);
        setTimeout(() => {
          setLoading(false);
          refreshPage();
        }, 2000);
      });
      }
    
    
  }
  };
  const filteredPosts = Data.filter((item) => {
    const searchLower = search.toLowerCase();
    return searchLower === ""
    ? item
    : item.carname
        .toLowerCase()
        .includes(search) ||
        item.category_id.categoryname
          .toLowerCase()
          .includes(search);
  });
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  // const currentPosts = Data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = ({ selected }) => {
    setCurrentpage(selected + 1);
  };

  useEffect(() => {
    getCars();
    getCarCategories();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const lowercaseValue = value.toLowerCase();
    setSearch(lowercaseValue);
  };

  // console.log("Data", Data);
  console.log("responseeeeeee.......",formData)
  return (
    <>
      <ToastContainer />
      <Dashboard>
        {/* ===============Start:: NewCategoryModel ================ */}
        <Fade top>
          <div
            className={`h-screen w-full bg-opacity-30 backdrop-blur-sm lg:mt-0 fixed flex items-center justify-center z-50 ${
              addNewCategoryModel === true ? "block" : "hidden"
            }`}
          >
            <div className="bg-white w-screen shadow-2xl rounded-lg p-4 pb-8">
              <div className="w-full ">
                <div className="modal-content">
                  {!isTablet && (
                    <div className="modal-header justify-content-center">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={(e) => categoryModel(e.preventDefault())}
                      >
                        <span>&times;</span>
                      </button>
                      <h2 className="modal-title text-black font-w600">
                        Add new Category
                      </h2>
                    </div>
                  )}
                  <div className="modal-body">
                    <div className="row justify-center items-center active show overflow-y-auto h-[65vh]">
                      <div className="col-lg-6">
                        <div className="basic-form">
                          <form>
                            <div className="custom-card">
                              <div className="card-body">
                                <div className="form-row">
                                  <div className="form-group col-md-12">
                                    <label className="text-black font-w600">
                                    Category Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={catFormData.categoryname}
                                      onChange={(e) =>
                                        setCatFormData({
                                          ...catFormData,
                                          categoryname: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-md-12">
                                    <label className="text-black font-w600">
                                      Description
                                    </label>
                                    <textarea
                                      className="form-control"
                                      rows="4"
                                      name="category_Description"
                                      id="comment"
                                      placeholder="Descriptions"
                                      value={catFormData.category_Description}
                                      onChange={(e) =>
                                        setCatFormData({
                                          ...catFormData,
                                          category_Description: e.target.value,
                                        })
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div>
                      <button
                        type="button"
                        className="bg-[#50C878]  light mr-3 p-2.5 text-white font-semibold rounded"
                        data-dismiss="modal"
                        onClick={(e) => categoryModel(e.preventDefault())}
                      >
                        Close
                      </button>
                      {loading ? (
                        <LoadingButton />
                      ) : (
                        <button
                          type="button"
                          className="bg-[#2d2f69] hover:bg-[#50C878] p-2.5 text-white font-semibold rounded"
                          onClick={handlerCatSubmit}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        {/* ===============End:: NewCategoryModel ================ */}

        {/* ===============Start:: NewServiceModel ================ */}
        <Fade top>
          <div
            className={`h-screen w-full bg-opacity-30 backdrop-blur-sm lg:mt-0 fixed flex items-center justify-center z-50 ${
              createNewDataModel === true ? "block" : "hidden"
            }`}
          >
            <div className="bg-white w-screen shadow-2xl rounded-lg p-4 pb-8 -ml-8">
              <div className="w-full ">
                <div className="modal-content">
                  {!isTablet && (
                    <div className="modal-header justify-content-center">
                      <h2 className="modal-title text-black font-w600">
                        Add new Car
                      </h2>
                    </div>
                  )}
                  <form onSubmit={handlerSubmit}>
                    <div className="modal-body">
                      <div className="row justify-center items-center active show overflow-y-auto h-[65vh] scrollbar-hide">
                        <div className="col-lg-6">
                          <div className="basic-form">
                            <div className="custom-card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  Car Basic Info
                                </h4>
                              </div>
                              <div className="card-body">
                                <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label className="text-black font-w600">Picture</label>
                                  <div className="border-dashed border-2 border-gray-300 rounded-lg p-4 flex flex-col items-center justify-center">
                                    <input
                                      type="file"
                                      className="hidden"
                                      id="fileUpload"
                                      multiple
                                      onChange={(e) => {
                                        const files = e.target.files;
                                        if (files) {
                                          try {
                                            const newFiles = Array.from(files).map((file) => ({
                                              binary:file,
                                              name: file.name,
                                              objectURL: URL.createObjectURL(file), // If you want to keep the URL for later use
                                            }));

                                            // Update formData with the new files
                                            setFormData((prevData) => ({
                                              ...prevData,
                                              imageData: [...prevData.imageData, ...newFiles], // Append new files
                                            }));
                                          } catch (error) {
                                            console.error("Error creating object URL:", error);
                                            toast.error("Failed to load image. Please try a different file.");
                                          }
                                        }
                                      }}
                                    />
                                    <label
                                      htmlFor="fileUpload"
                                      className="cursor-pointer flex flex-col items-center justify-center"
                                    >
                                      <span className="text-[#000]">
                                        Drop your image here, or <span className="text-[#50C878]">browse</span>.
                                      </span>
                                      <span className="text-gray-500 text-xs mt-2">Supports: JPG, JPEG2000, PNG</span>
                                    </label>
                                  </div>

                                  {/* List of uploaded file names */}
                                  {formData?.imageData?.length > 0 && (
                                    <div className="mt-4">
                                      <h3 className="font-w600 text-sm">Uploaded Files:</h3> {/* Minimized font size */}
                                      <ul className="list-disc pl-5">
                                        {formData.imageData.map((file, i) => (
                                          <li key={i} className="flex items-center justify-between mb-2">
                                            <span className="text-gray-700">{file.name}</span>
                                            <button
                                              className="text-red-500 text-xs hover:text-red-700 transition duration-200"
                                              onClick={() => {
                                                const updatedImages = formData.imageData.filter((_, index) => index !== i);
                                                setFormData({ ...formData, imageData: updatedImages });
                                              }}
                                            >
                                              Remove
                                            </button>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>

                                  <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                    Car Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      defaultValue={formData.carname}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          carname: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                    Car category
                                    </label>
                                    <select
                                      id="inputState"
                                      className="form-control"
                                      defaultValue={formData.category_id}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          category_id: e.target.value,
                                        })
                                      }
                                      required
                                    >
                                       <option
                                        
                                          value=""
                                        >choose a category</option>
                                       {Category.map((category) => (
                                        <option
                                          key={category._id}
                                          value={category._id}
                                        >

                                          {category.categoryname}
                                        </option>
                                      ))}

                                    </select>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                      Fuel Type
                                    </label>
                                    <select
                                      id="inputState"
                                      className="form-control"
                                      defaultValue={formData.fuel}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          fuel: e.target.value,
                                        })
                                      }
                                      required
                                    >
                                      <option value="">Choose...</option>
                                      <option value="GASOLINE">Gasoline</option>
                                      <option value="DIESEL">Diesel</option>
                                      {/* {Category.map((category) => (
                                        <option
                                          key={category._id}
                                          value={category._id}
                                        >
                                          {category.fueltype}
                                        </option>
                                      ))} */}
                                    </select>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                      Transmission
                                    </label>
                                    <select
                                      id="inputState"
                                      className="form-control"
                                      defaultValue={formData.transmission}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          transmission: e.target.value,
                                        })
                                      }
                                      required
                                    >
                                      <option value="">Choose...</option>
                                      <option value="MANUAL">Manual</option>
                                      <option value="AUTOMATIC">Automatic</option>
                                      
                                    </select>
                                  </div>
                                  <div className="form-group col-md-12">
                                    <label className="text-black font-w600">
                                    Number of Seats
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder=""
                                      defaultValue={formData.seats}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          seats: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                            <div className="custom-card">
                              <div className="card-header">
                                <h4 className="card-title">Pricing Info</h4>
                              </div>
                              <div className="card-body">
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                      Rent price
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      defaultValue={formData.amount}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          amount: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>

                                  {/* <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                      Duration
                                    </label>

                                    <select
                                      id="inputState"
                                      className="form-control"
                                      defaultValue={formData.duration}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          duration: e.target.value,
                                        })
                                      }
                                      required
                                    >
                                      <option value="">Choose...</option>
                                      <option value="5">Hour</option>
                                      <option value="10">Day</option>
                                    </select>
                                  </div>
                                */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                  
                    <div className="modal-footer">
                      <div>
                        <button
                          type="button"
                          className="bg-[#50C878]  light mr-3 p-2.5 text-white font-semibold rounded"
                          data-dismiss="modal"
                          onClick={(e) => removeModel(e.preventDefault())}
                        >
                          Close
                        </button>
                        {loading ? (
                          <LoadingButton />
                        ) : (
                          <button
                            type="submit"
                            className="bg-[#2d2f69] hover:bg-[#50C878] p-2.5 text-white font-semibold rounded"
                          >
                            Add Car
                          </button>
                        )}
                      </div>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        {/* ===============End:: NewServiceModel ================ */}

        {/* ===============Start:: UpdateModel ================ */}
        <Fade right>
          <div
            className={`h-screen w-full bg-opacity-30 backdrop-blur-sm lg:mt-0 fixed flex items-center justify-center z-50 ${
              updateDataModel === true ? "block" : "hidden"
            }`}
          >
            <div className="bg-white w-screen shadow-2xl rounded-lg p-4 pb-8 -ml-8">
              <div className="w-full ">
                <div className="modal-content">
                  {!isTablet && (
                    <div className="modal-header justify-content-center">
                      <h2 className="modal-title text-black font-w600">
                        Update Car
                      </h2>
                    </div>
                  )}
                  <div className="modal-body">
                    <div className="row justify-center items-center active show overflow-y-auto h-[65vh] scrollbar-hide">
                      <div className="col-lg-6">
                        <div className="basic-form">
                          
                            <div className="custom-card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  Car Basic Info
                                </h4>
                              </div>
                              <div className="card-body">
                                <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label className="text-black font-w600">Picture</label>
                                  <div className="border-dashed border-2 border-gray-300 rounded-lg p-4 flex flex-col items-center justify-center">
                                    <input
                                      type="file"
                                      className="hidden"
                                      id="fileUpload"
                                      multiple
                                      onChange={(e) => {
                                        const files = e.target.files;
                                        if (files) {
                                          try {
                                            const newFiles = Array.from(files).map((file) => ({
                                              binary:file,
                                              name: file.name,
                                              objectURL: URL.createObjectURL(file), // If you want to keep the URL for later use
                                            }));

                                            // Update formData with the new files
                                           
                                            setFormData((prevData) => ({
                                              ...prevData,
                                              imageData: [...prevData.imageData, ...newFiles], // Append new files
                                            }));
                                            
                                          } catch (error) {
                                            console.error("Error creating object URL:", error);
                                            toast.error("Failed to load image. Please try a different file.");
                                          }
                                        }
                                      }}
                                    />
                                    <label
                                      htmlFor="fileUpload"
                                      className="cursor-pointer flex flex-col items-center justify-center"
                                    >
                                      <span className="text-[#000]">
                                        Drop your image here, or <span className="text-[#50C878]">browse</span>.
                                      </span>
                                      <span className="text-gray-500 text-xs mt-2">Supports: JPG, JPEG2000, PNG</span>
                                    </label>
                                  </div>

                                  {/* List of uploaded file names */}
                                  {formData?.imageData?.length > 0 && (
                                    <div className="mt-4">
                                      <h3 className="font-w600 text-sm">Uploaded Files:</h3> {/* Minimized font size */}
                                      <ul className="list-disc pl-5">
                                        {formData.imageData.map((file, i) => (
                                          <li key={i} className="flex items-center justify-between mb-2">
                                            <span className="text-gray-700">{file.name}</span>
                                            <button
                                              className="text-red-500 text-xs hover:text-red-700 transition duration-200"
                                              onClick={() => {
                                                const updatedImages = formData.imageData.filter((_, index) => index !== i);
                                                setFormData({ ...formData, imageData: updatedImages });
                                              }}
                                            >
                                              Remove
                                            </button>
                                          </li>
                                        ))}
                                        
                                      </ul>
                                    </div>
                                  )}
                                </div>

                                  <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                    Car Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      defaultValue={formData.carname}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          carname: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                    Car category
                                    </label>
                                    <select
                                      id="inputState"
                                      className="form-control"
                                      defaultValue={formData.category_id}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          category_id: e.target.value,
                                        })
                                      }
                                      required
                                    >
                                       <option value={formData.category_id}>{formData.category_id.categoryname}</option>
                                       {Category.map((category) => (
                                        <option
                                          key={category._id}
                                          value={category._id}
                                        >

                                          {category.categoryname}
                                        </option>
                                      ))}

                                    </select>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                      Fuel Type
                                    </label>
                                    <select
                                      id="inputState"
                                      className="form-control"
                                      defaultValue={formData.fuel}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          fuel: e.target.value,
                                        })
                                      }
                                      required
                                    >
                                     <option value={formData.fuel}>{formData.fuel}</option>

                                      <option value="GASOLINE">GASOLINE</option>
                                      <option value="DIESEL">DIESEL</option>
                                      {/* {Category.map((category) => (
                                        <option
                                          key={category._id}
                                          value={category._id}
                                        >
                                          {category.fueltype}
                                        </option>
                                      ))} */}
                                    </select>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                      Transmission
                                    </label>
                                    <select
                                      id="inputState"
                                      className="form-control"
                                      defaultValue={formData.transmission}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          transmission: e.target.value,
                                        })
                                      }
                                      required
                                    >
                                      <option value={formData.transmission}>{formData.transmission}</option>
                                      <option value="MANUAL">MANUAL</option>
                                      <option value="AUTOMATIC">AUTOMATIC</option>
                                      
                                    </select>
                                  </div>
                                  <div className="form-group col-md-12">
                                    <label className="text-black font-w600">
                                    Number of Seats
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder=""
                                      defaultValue={formData.seats}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          seats: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                            <div className="custom-card">
                              <div className="card-header">
                                <h4 className="card-title">Pricing Info</h4>
                              </div>
                              <div className="card-body">
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                      Rent price
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      defaultValue={formData.amount}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          amount: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </div>

                                  {/* <div className="form-group col-md-6">
                                    <label className="text-black font-w600">
                                      Duration
                                    </label>

                                    <select
                                      id="inputState"
                                      className="form-control"
                                      defaultValue={formData.duration}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          duration: e.target.value,
                                        })
                                      }
                                      required
                                    >
                                      <option value="">Choose...</option>
                                      <option value="5">Hour</option>
                                      <option value="10">Day</option>
                                    </select>
                                  </div>
                                */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                  <div className="modal-footer">
                    <div>
                      <button
                        type="button"
                        className="bg-[#50C878]  light mr-3 p-2.5 text-white font-semibold rounded"
                        data-dismiss="modal"
                        onClick={(e) => updateModel(e.preventDefault())}
                      >
                        Close
                      </button>
                      {loading ? (
                        <LoadingButton />
                      ) : (
                        <button
                          type="button"
                          className="bg-[#2d2f69] hover:bg-[#50C878] p-2.5 text-white font-semibold rounded"
                          onClick={handleUpdate}
                        >
                          Save changes
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        {/* ===============End:: UpdateModel ================ */}

        {/* ====================== Start::  deleteDataModel =============================== */}
        <Fade right>
          <div
            className={`min-h-full w-screen z-50 bg-opacity-30 backdrop-blur-sm fixed flex items-center justify-center px-4 -ml-6 ${
              deleteDataModel === true ? "block" : "hidden"
            }`}
          >
            <div
              className={`bg-white ${
                isTablet ? "w-full" : "w-1/2"
              } shadow-2xl rounded-lg p-4 pb-8`}
            >
              <div className="card-title w-full flex  flex-wrap justify-center items-center  ">
                <h1 className="font-bold text-sm text-center w-11/12">
                  Delete Car
                </h1>
                <hr className=" bg-primary border-b w-full" />
              </div>
              <div className="card-body">
                <form className=" px-8">
                  <div>
                    <h2 className="text-base m-4">
                      Do you really want permanently delete{" "}
                      <span className="italic text-black">
                        {RowData?.carname}
                      </span>
                    </h2>
                  </div>
                  <div className="w-full flex justify-between">
                    <button
                      className="btn btn-danger light shadow-none"
                      onClick={(e) => deleteModel(e.preventDefault())}
                    >
                      Cancel
                    </button>
                    {loading ? (
                      <LoadingButton />
                    ) : (
                      <button
                        className="btn btn-outline-danger btn-s shadow-none"
                        onClick={handleDelete}
                      >
                        remove
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fade>
        {/* =========================== End::  deleteDataModel =============================== */}

        <div className={`content-body mt-[3rem] ${!isTablet && "ml-52"}`}>
          <div className="container-fluid">
            <div
              className={`relative form-head mb-3 flex items-center justify-between`}
            >
              {!isTablet && (
                <div className="mr-auto d-lg-block">
                  <h3 className="text-black font-w500 mb-6">Cars</h3>
                </div>
              )}
              <div className="dropdown custom-dropdown">
                {permissions.indexOf("add-service") !== -1 && (
                  <div
                  className="btn btn-sm btn-primary light d-flex align-items-center svg-btn shadow-none"
                  data-toggle="dropdown"
                  onClick={handleDropDown}
                  >
                    <i className="bi bi-plus-lg"></i>
                    <div className="text-left ml-3">
                      <span className="d-block fs-16">Add</span>
                      <small className="d-block fs-12">
                        Add Category or Car
                      </small>
                    </div>
                    <i className="fa fa-angle-down scale5 ml-3"></i>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 -ml-4 mb-4 flex justify-center md:justify-start">
              <input
                type="text"
                name="name"
                className="form-input w-full  py-2 px-4 rounded-md border border-solid border-[#d1d1d1] focus:border-[#e3b04b] focus:ring focus:ring-[#e3b04b]"
                value={search}
                onChange={handleInputChange}
                placeholder="Search by  service Name or Category"
              />
            </div>
            <div className="row">
              {isTablet && (
                <div className="mr-auto d-lg-block mb-4">
                  {isTablet && (
                    <h2
                      className={`text-black font-w500 mb-6 ${
                        isTablet && "text-lg pl-4"
                      }`}
                    >
                      Car
                    </h2>
                  )}
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="w-[40vh] py-2 px-2 rounded"
                      value={search}
                      onChange={handleInputChange}
                      placeholder="Search by Name or Category"
                    />
                  </div>
                </div>
              )}
              <div className="col-12">
                {loading && <Skeleton />}
                {!loading && (
                  <div className="table-responsive">
                    <div className="card">
                      {permissions.indexOf("export-service") !== -1 &&
                        !isTablet && (
                          <div className=" absolute right-6 items-center justify-center top-3 flex gap-2">
                            <small>Export</small>
                            <ReactHTMLTableToExcel
                              id="test-table-xls-button"
                              className="btn btn-sm btn-primary light flex items-center svg-btn shadow-none z-15"
                              table="table-to-xls"
                              filename="Barber-Service"
                              sheet="Suppliers"
                              buttonText={<FaFileExport />}
                            />
                          </div>
                        )}
                      <div className="card-body">
                        <div className="table-responsive">
                          <table id="table-to-xls" className="display w-full">
                            <thead>
                              <tr className="border-b">
                                {!isTablet && <th className="py-6 pr-2">#</th>}
                                <th className="py-6">Name</th>
                                {!isTablet && (
                                  <>
                                    <th className="py-6">Category</th>
                                    {/* <th className="py-6">Duration</th> */}
                                  </>
                                )}
                                <th className="py-6">Price(RWF)</th>
                                {permissions.indexOf(
                                  "edit-service",
                                  "delete-service"
                                ) !== -1 && <th className="py-6">Action</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {currentPosts
                                .filter((item) => {
                                  return search.toLowerCase() === ""
                                    ? item
                                    : item.carname
                                        .toLowerCase()
                                        .includes(search) ||
                                        item.category_id.categoryname
                                          .toLowerCase()
                                          .includes(search);
                                })
                                .map((item, index) => (
                                  <tr key={index} className="border-b">
                                    {!isTablet && (
                                      <td className="py-3 mr-3">{index + 1}</td>
                                    )}
                                    <td className="py-3">{item.carname}</td>
                                    {!isTablet && (
                                      <>
                                        <td className="py-3">
                                          {item.category_id.categoryname}
                                        </td>
                                        {/* <td className="py-3">
                                          {item.duration + " " + "mins"}
                                        </td> */}
                                      </>
                                    )}
                                    <td className="py-3">{item.amount}</td>
                                    <td className="py-3">
                                      {" "}
                                      {permissions.indexOf("edit-service") !==
                                        -1 && (
                                        <button
                                          className="bg-gray-900 hover:bg-[#50C878] rounded shadow btn-xs sharp mr-1 bell bell-link"
                                          data-toggle="modal"
                                          data-target="#new-supplier-modal"
                                          onClick={() =>
                                            updateModel(
                                              setFormData({...item,imageData:[]}),
                                              setId(item._id)
                                            )
                                          }
                                        >
                                          <i className="fa fa-pencil text-white"></i>
                                        </button>
                                      )}
                                      {permissions.indexOf("delete-service") !==
                                        -1 && (
                                        <button
                                          className="btn btn-danger shadow-none btn-xs sharp"
                                          onClick={() => {
                                            deleteModel(
                                              SetRowData(item),
                                              setId(item._id)
                                            );
                                          }}
                                        >
                                          <i className="fa fa-trash-o"></i>
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <ReactPaginate
                        onPageChange={paginate}
                        pageCount={Math.ceil(Data.length / postsPerPage)}
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        containerClassName={"pagination"}
                        pageLinkClassName={"page-number"}
                        previousLinkClassName={"page-number"}
                        nextLinkClassName={"page-number"}
                        activeLinkClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div
               className={`${
                open ? "block" : "hidden"
              } absolute right-11 -mt-24 w-[15rem] rounded-lg shadow-lg border bg-white z-50`}
              onClick={handleDropDown}
            >
                <ul className="space-y-3 p-2">
                  <li className="font-medium">
                    <button
                      className="flex w-full justify-start items-center text-[14px] text-gray-800 hover:text-white hover:bg-black p-2 rounded-md transition duration-150 ease-in-out"
                      onClick={removeModel}
                    >
                      New Car
                    </button>
                  </li>
                  <li className="font-medium">
                    <button
                      className="flex w-full justify-start items-center text-[14px] text-gray-800 hover:text-white hover:bg-black p-2 rounded-md transition duration-150 ease-in-out"
                      onClick={categoryModel}
                    >
                      Add new Category
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  );
};

export default Car;
