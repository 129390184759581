/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Dashboard from "../../Dashboard";
import { axiosRequest } from "../../../api";
import { DatePicker, Button } from "antd";
import ReportTable from "./ReportTable";
import SingleCashier from "./DataFor1Cashier";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { getUser } from "../../utils/common";
import { getAllCartRequestAction } from "../../../store/cart/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateTotalProductCost,
  calculateTotalServiceCost,
} from "../../../utils/helper";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FaFileExport } from "react-icons/fa";
import './TableStyles.css';
const { RangePicker } = DatePicker;
const Report_URL = "sales/report";
const Team_URL = "team";
const Services_URL = "newWay/cart/servSales";

const Emp_URL = "newWay/cart/empSales";
const Prod_URL = "newWay/cart/prodSales";
const Exp_URL = "newWay/cart/expenses";
const Momo_URL = "newWay/cart/momo";
const Pos_URL = "newWay/cart/pos";

const EServices_URL = "newWay/cart/servESales";

const EEmp_URL = "newWay/cart/empESales";
const EProd_URL = "newWay/cart/prodESales";
const EExp_URL = "newWay/cart/Eexpenses";

const MServices_URL = "newWay/cart/servMSales";

const MEmp_URL = "newWay/cart/empMSales";
const MProd_URL = "newWay/cart/prodMSales";
const MExp_URL = "newWay/cart/Mexpenses";
const Usershifts_URL = "newWay/cart/shiftUsers";


const Mmomo_URL = "newWay/cart/Mmomo";
const Mpos_URL = "newWay/cart/Mpos";
const Emomo_URL = "newWay/cart/Emomo";
const Epos_URL = "newWay/cart/Epos";

const Report = () => {
  const { cart } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [search, setSearch] = useState(formattedDate);
  const [search2, setSearch2] = useState('');

  const [rangeVisible, setRangeVisible] = useState(false);
  const [shiftsVisible, setShiftsVisible] = useState(true);

  const [Data, setData] = useState([]);
  const [id, setId] = useState("");
  const [userId, setUserId] = useState("all");

  const [getSalesByUserIdData, setGetSalesByUserIdData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const isTablet = useMediaQuery("(max-width: 960px)");
  const handleDropDown = () => setOpen(!open);
  const user = getUser();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isActiveWeek, setIsActiveWeek] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [barberData, setBarberData] = useState([]);
  const [topServiceData, setTopServiceData] = useState([]);
  const [topEmpData, setTopEmpData] = useState([]);
  const [topProdData, setTopProdData] = useState([]);
  const [topExpData, setTopExpData] = useState([]);

  const [serviceData, setServiceData] = useState([]);
  const [empData, setEmpData] = useState([]);
  const [prodData, setProdData] = useState([]);
  const [expData, setExpData] = useState([]);
  const [posData, setPosData] = useState([]);
  const [momoData, setMomoData] = useState([]);

  const [topMServiceData, setTopMServiceData] = useState([]);
  const [topMEmpData, setTopMEmpData] = useState([]);
  const [topMProdData, setTopMProdData] = useState([]);
  const [topMExpData, setTopMExpData] = useState([]);
  const [shiftUserData, setShiftUserData] = useState([]);

  const [topMmomoData, setTopMmomoData] = useState([]);
  const [topMposData, setTopMposData] = useState([]);
  const [topEposData, setTopEposData] = useState([]);
  const [topEmomoData, setTopEmomoData] = useState([]);
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));
  const changeDateFormatt = () => {
    const date = new Date();
    
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed in JavaScript
    const day = date.getDate().toString().padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate);
    return  formattedDate; // Outputs: 2024-04-02
    
  }
  function formatDate(dateString) {
    // Create a new Date object from the input string
    if(dateString == ''){
      return "No date seleted"

    }else{
      const date = new Date(dateString);
  
      // Define options for formatting the date
      const options = { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: '2-digit' 
      };
    
      // Use toLocaleDateString to format the date
      return date.toLocaleDateString('en-US', options);

    }
   
  }
  function formatDatee(dateString) {
    // Create a new Date object from the input string
    if(dateString == ''){
      return "No date seleted"

    }else{
      let dates = String(dateString).split(",");
  
    
      const date1 = new Date(dates[0]);
      const date2 = new Date(dates[1]);
  
      // Define options for formatting the date
      const options = { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: '2-digit' 
      };
    
      // Use toLocaleDateString to format the date
      return   `From ${date1.toLocaleDateString('en-US', options)} to ${date2.toLocaleDateString('en-US', options)}`;

    }
   
  }
  const getShiftUsers = () => {
    axiosRequest
      .get(Usershifts_URL+'?date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setShiftUserData(response);
        console.log("users--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getMomo = () => {
    axiosRequest
      .get(Momo_URL+'?date='+search2)
      .then((res) => {
        const response = res.data.data;
        
        setMomoData(response);
        console.log("mmomo--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getMmomo = () => {
    axiosRequest
      .get(Mmomo_URL+'?date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setTopMmomoData(response);
        console.log("mmomo--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getEmomo = () => {
    axiosRequest
      .get(Emomo_URL+'?date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setTopEmomoData(response);
        console.log("expenses--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getPos = () => {
    axiosRequest
      .get(Pos_URL+'?date='+search2)
      .then((res) => {
        const response = res.data.data;
        
        setPosData(response);
        console.log("expenses--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getEpos = () => {
    axiosRequest
      .get(Epos_URL+'?date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setTopEposData(response);
        console.log("expenses--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getMpos = () => {
    axiosRequest
      .get(Mpos_URL+'?date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setTopMposData(response);
        console.log("expenses--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getExpenseDone = () => {
    axiosRequest
      .get(Exp_URL+'?date='+search2)
      .then((res) => {
        const response = res.data.data;
        
        setExpData(response);
        console.log("expenses--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getEExpenseDone = () => {
    axiosRequest
      .get(EExp_URL+'?date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setTopExpData(response);
        console.log("expenses--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getProductSales = () => {
    axiosRequest
      .get(Prod_URL+'?status=paid&date='+search2)
      .then((res) => {
        const response = res.data.data;
        
        setProdData(response);
        console.log("products--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getEProductSales = () => {
    axiosRequest
      .get(EProd_URL+'?status=paid&date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setTopProdData(response);
        console.log("products--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getEmployeeSales = () => {
    axiosRequest
      .get(Emp_URL+'?status=paid&date='+search2)
      .then((res) => {
        const response = res.data.data;
        
        setEmpData(response);
        console.log("emplooo--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getEEmployeeSales = () => {
    axiosRequest
      .get(EEmp_URL+'?status=paid&date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setTopEmpData(response);
        console.log("emplooo--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getServiceSales = () => {
    axiosRequest
      .get(Services_URL+'?status=paid&date='+search2)
      .then((res) => {
        const response = res.data.data;
       
        setServiceData(response);
        console.log("mserviiii--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getEServiceSales = () => {
    axiosRequest
      .get(EServices_URL+'?status=paid&date='+search)
      .then((res) => {
        const response = res.data.data;
       
        setTopServiceData(response);
        console.log("mserviiii--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getMExpenseDone = () => {
    axiosRequest
      .get(MExp_URL+'?date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setTopMExpData(response);
        console.log("Mexpenses--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getMProductSales = () => {
    axiosRequest
      .get(MProd_URL+'?status=paid&date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setTopMProdData(response);
        console.log("Mproducts--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getMEmployeeSales = () => {
    axiosRequest
      .get(MEmp_URL+'?status=paid&date='+search)
      .then((res) => {
        const response = res.data.data;
        
        setTopMEmpData(response);
        console.log("Memplooo--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getMServiceSales = () => {
    axiosRequest
      .get(MServices_URL+'?status=paid&date='+search)
      .then((res) => {
        const response = res.data.data;
       
        setTopMServiceData(response);
        console.log("Mserviiii--------",response);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getReport = async () => {
    if (user[0]?.type !== "CASHIER_C") {
      const url = `sales/user/${user[0]?._id}`;
      await axiosRequest
        .get(url)
        .then((response) => {
          const result = response.data.data;
          setGetSalesByUserIdData(result);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else if (search) {
      await axiosRequest
        .get(`${Report_URL}?date=${search}`)
        .then((res) => {
          const response = res.data.data;
          setReportData(response);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else if (selectedOption) {
      await axiosRequest
        .get(`${Report_URL}?weeks=${selectedOption}`)
        .then((res) => {
          const response = res.data.data;
          setReportData(response);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else if (selectedMonth) {
      await axiosRequest
        .get(`${Report_URL}?months=${selectedMonth}`)
        .then((res) => {
          const response = res.data.data;
          setReportData(response);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      await axiosRequest
        .get(`${Report_URL}?`)
        .then((res) => {
          const response = res.data.data;
          setReportData(response);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const getTeamMember = () => {
    // setLoading(true);
    axiosRequest
      .get(Team_URL)
      .then((res) => {
        // setLoading(false);
        const response = res.data.data;
        const result = response.filter(
          (element) => element.type === "CASHIER_C"
        );
        setData(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getAllSalesByUserId = async () => {
    const url = `sales/user/${id}`;
    await axiosRequest
      .get(url)
      .then((response) => {
        const result = response.data.data;
        setGetSalesByUserIdData(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    getMmomo();
    getEmomo();
    getMpos();
    getEpos();


  
   

    getShiftUsers();
    getEExpenseDone();
    getEProductSales();
    getEServiceSales();
    getEEmployeeSales();
    getMExpenseDone();
    getMProductSales();
    getMServiceSales();
    getMEmployeeSales();
    getTeamMember();
    getReport();
  }, [search, selectedOption, selectedMonth]);

  useEffect(() => {
   if(userId && search)
    {
      handleUserSelect();
    }
  }, [userId,search]);

  useEffect(() => {

    getExpenseDone();
    getProductSales();
    getServiceSales();
    getEmployeeSales();
    getMomo();
    getPos();

   }, [search2]);
  const singleUserPtoductTotal = getSalesByUserIdData.map((item) =>
    item.productStore.reduce((acc, obj) => acc + obj?.productQnt * obj?.cost, 0)
  );
  const singleUserGeneralProductTotal = singleUserPtoductTotal.reduce(
    (acc, cur) => acc + cur,
    0
  );

  const singleUserServiceTotal = getSalesByUserIdData.map((item) =>
    item.servicesStore.reduce((acc, obj) => acc + obj?.cost, 0)
  );
  const singleUserGeneralServiceTotal = singleUserServiceTotal.reduce(
    (acc, cur) => acc + cur,
    0
  );
  const changeDateFormat = (isoDateString) => {
    const date = new Date(isoDateString);
    
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed in JavaScript
    const day = date.getDate().toString().padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate);
    return  formattedDate; // Outputs: 2024-04-02
    
  }
  function isSameWeek(date1, date2) {
    const startOfWeek = (d) => {
    const diff = d.getDate() - d.getDay();
    return new Date(d.setDate(diff));
  };
    // Set the hours, minutes, seconds, and milliseconds to 0 for a fair comparison
    const startOfDate1 = startOfWeek(new Date(date1)).setHours(0, 0, 0, 0);
    const startOfDate2 = startOfWeek(new Date(date2)).setHours(0, 0, 0, 0);
  
    return startOfDate1 === startOfDate2;
  }
  function isSameMonth(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
  }
  const handleWeeklyClick = () => {
    setSelectedOption(1);
    setIsActiveWeek(true);
    setSelectedMonth(null);
    // setSearch("");
    if(userId === "all" && search){
      const newData = cart?.all?.data.filter((user) => (isSameWeek(changeDateFormat(user.createdAt),search))); // Assuming each user has an 'id' property
    setFilteredData(newData);
    console.log('filtered data by uuser', newData);

    }else{
    const newData = cart?.all?.data.filter((user) => (user.user._id === userId && isSameWeek(changeDateFormat(user.createdAt),search))); // Assuming each user has an 'id' property
    setFilteredData(newData);
    console.log('filtered data by user', newData);
    }
  };
  const handleMonthButton = () => {
    setSelectedMonth(1);
    setSelectedOption(null);
    // setSearch("");
    setIsActiveWeek(false);
    if(userId === "all" && search){
      const newData = cart?.all?.data.filter((user) => (isSameMonth(changeDateFormat(user.createdAt),search))); // Assuming each user has an 'id' property
    setFilteredData(newData);
    console.log('filtered data by uuser', newData);

    }else{
    const newData = cart?.all?.data.filter((user) => (user.user._id === userId && isSameMonth(changeDateFormat(user.createdAt),search))); // Assuming each user has an 'id' property
    setFilteredData(newData);
    console.log('filtered data by user', newData);
    }
    
  };
  const handleInputChange = (date, dateString) => {
    setSearch(dateString);
    setRangeVisible(false);
    setShiftsVisible(true);
    setSelectedMonth(null);
    setSelectedOption(null);
    setIsActiveWeek(false);
    console.log(`dateekkkkk.......${date}`);
    console.log(`dateee.......${dateString}`);
    
    // if(search){
    //   const newData = cart?.all?.data; // Assuming each user has an 'id' property
    // setFilteredData(newData);
    // console.log('filtered data by user', newData);

    // }else{
    // const newData = cart?.all?.data.filter((user) => (user.user._id === id && changeDateFormat(user.createdAt) === search)); // Assuming each user has an 'id' property
    // setFilteredData(newData);
    // console.log('filtered data by user', newData);
    // }
  };
  const handleRangeInputChange = (date, dateString) => {
     // Output: 2024-08-27
    setSearch2(dateString);

    setShiftsVisible(false);
    setRangeVisible(true);
    


    setSelectedMonth(null);
    setSelectedOption(null);
    setIsActiveWeek(false);
    
    console.log(`dateee1.......${dateString}`);
   
    
    // if(search){
    //   const newData = cart?.all?.data; // Assuming each user has an 'id' property
    // setFilteredData(newData);
    // console.log('filtered data by user', newData);

    // }else{
    // const newData = cart?.all?.data.filter((user) => (user.user._id === id && changeDateFormat(user.createdAt) === search)); // Assuming each user has an 'id' property
    // setFilteredData(newData);
    // console.log('filtered data by user', newData);
    // }
  };
  const handleUserSelect = () => {
    if (!cart || !cart.all || !cart.all.data) {
      console.error('Cart data is not available');
      return;
    }
    if(userId === "all" && search){
      const newData = cart?.all?.data.filter((user) => (changeDateFormat(user.createdAt) === search)); // Assuming each user has an 'id' property
    setFilteredData(newData);
    console.log('filtered data by uuser', newData);

    }else{
    const newData = cart?.all?.data.filter((user) => (user.user._id === userId && changeDateFormat(user.createdAt) === search)); // Assuming each user has an 'id' property
    setFilteredData(newData);
    console.log('filtered data by user', newData);
    }
  };
  
  useEffect(() => {
    if(!cart?.isFetching && cart?.all){
      // setFilteredData(cart?.all?.data);
      handleUserSelect();
    }
  }, [cart?.isFetching]);
  useEffect(() => {
    getAllCartRequestAction("?status=paid")(dispatch);
    // setFilteredData(cart?.all?.data);
  }, [dispatch]);

  const overAllTotal = filteredData.reduce(
    (acc, item) => acc + item?.amoutPaid,
    0
  );
const tableDate = formatDate(search);
const tableDate2 = formatDatee(search2);
const tableArray = [17,7,7,7,7,7,7,7,7,7,7];
const productsSubTotal = prodData.reduce((acc, product) => {
  return acc + product.totalAmount;
}, 0);
const mproductsSubTotal = topMProdData.reduce((acc, product) => {
  return acc + product.totalAmount;
}, 0);
const eproductsSubTotal = topProdData.reduce((acc, product) => {
  return acc + product.totalAmount;
}, 0);
const servicesSubTotal = serviceData.reduce((acc, service) => {
  return acc + service.totalAmount;
}, 0);
const mservicesSubTotal = topMServiceData.reduce((acc, service) => {
  return acc + service.totalAmount;
}, 0);
const eservicesSubTotal = topServiceData.reduce((acc, service) => {
  return acc + service.totalAmount;
}, 0);
const expensesTotal = expData.reduce((acc, expense) => {
  return acc + expense.totalAmount;
}, 0);
const mExpensesTotal = topMExpData.reduce((acc, expense) => {
  return acc + expense.totalAmount;
}, 0);
const eExpensesTotal = topExpData.reduce((acc, expense) => {
  return acc + expense.totalAmount;
}, 0);
const emplTotal = empData.reduce((acc, employee) => {
  return acc + employee.totalAmount;
}, 0);
const mEmplTotal = topMEmpData.reduce((acc, employee) => {
  return acc + employee.totalAmount;
}, 0);
const eEmplTotal = topEmpData.reduce((acc, employee) => {
  return acc + employee.totalAmount;
}, 0);
const momoTotal = momoData.reduce((acc, momo) => {
  return acc + momo.amoutPaid;
}, 0);
const mMomoTotal = topMmomoData.reduce((acc, momo) => {
  return acc + momo.amoutPaid;
}, 0);
const eMomoTotal = topEmomoData.reduce((acc, momo) => {
  return acc + momo.amoutPaid;
}, 0);
const posTotal = posData.reduce((acc, pos) => {
  return acc + pos.amoutPaid;
}, 0);
const mPosTotal = topMposData.reduce((acc, pos) => {
  return acc + pos.amoutPaid;
}, 0);
const ePosTotal = topEposData.reduce((acc, pos) => {
  return acc + pos.amoutPaid;
}, 0);
const mDailyEntryTotal = (mEmplTotal + mproductsSubTotal + mservicesSubTotal);
const eDailyEntryTotal = (eEmplTotal + eproductsSubTotal + eservicesSubTotal);
const mCashTotal = (mDailyEntryTotal - mMomoTotal - mPosTotal - mExpensesTotal);
const eCashTotal = (eDailyEntryTotal - eMomoTotal - ePosTotal - eExpensesTotal);
const grandTotal = (mDailyEntryTotal + eDailyEntryTotal);
const deposited = (mCashTotal + eCashTotal);
const allGrandTotal = (emplTotal + productsSubTotal + servicesSubTotal);
const allDeposited = (allGrandTotal - momoTotal - posTotal - expensesTotal);
  return (
    <Dashboard>
      <div className={`content-body mt-[3rem] ${!isTablet && "ml-52"}`}>
        <div className="container-fluid">
          <div
            className={`relative form-head mb-3 flex items-center justify-between`}
          >
            {!isTablet && (
                <div className="mr-auto d-lg-block">
                  <h2 className={`text-black font-w500 mb-6`}>Report</h2>
                </div>
              )}
              {/* {!isTablet && (
              // <>
              //   <div
              //     className="absolute right-0 btn btn-sm btn-primary light d-flex align-items-center svg-btn shadow-none"
              //     data-toggle="dropdown"
              //     onClick={handleDropDown}
              //   >
              //     <div className="text-left ml-3">
              //       <span className="d-block fs-16">Filter by Cashiers</span>
              //     </div>
              //     <i className="fa fa-angle-down scale5 ml-3"></i>
              //   </div>

              //   <div
              //     className={`${
              //       open ? "block" : "hidden"
              //     } absolute right-0 mt-52  w-[15rem] rounded-lg shadow-lg border bg-white z-50`}
              //     onClick={handleDropDown}
              //   >
              //     <ul className="space-y-1 p-2">
              //       {Data.map((item, index) => (
              //         <li key={index} className="font-medium">
              //           <button
              //             className="flex w-full justify-start items-center text-[14px] text-gray-800 hover:text-white hover:bg-black p-2 rounded-md transition duration-150 ease-in-out"
              //             onClick={(e) => setUserId(item._id)}
              //           >
              //             {item.firstname}
              //           </button>
              //         </li>
              //       ))}
              //       <li>
              //         <button
              //           className="flex w-full justify-start items-center text-[14px] text-gray-800 hover:text-white hover:bg-black p-2 rounded-md transition duration-150 ease-in-out"
              //           onClick={(e) => setUserId("all")}
              //         >
              //           All
              //         </button>
              //       </li>
              //     </ul>
              //   </div>



              // </>
            )} */}
</div>
            
            <div className="mr-auto d-lg-block">
              <div className={`${!isTablet ? "flex items-center space-x-8" : "block space-y-2"} mb-4`}>
                <DatePicker
                  onChange={handleInputChange}
                  defaultValue={dayjs()}
                  placeholder="Search by Date"
                  className="w-[30vh] py-2 px-2 rounded"
                />
                <RangePicker 
                onChange={handleRangeInputChange}
                className="w-[30vh] py-2 px-2 rounded"
                />
                {/* <div className="flex space-x-4">
                  <Button
                    onClick={handleWeeklyClick}
                    style={{
                      height: "40px",
                      backgroundColor: isActiveWeek ? "black" : "white",
                      color: isActiveWeek ? "white" : "black",
                    }}
                  >
                    Weekly
                  </Button>
                  <Button
                    onClick={handleMonthButton}
                    style={{
                      height: "40px",
                      backgroundColor: selectedMonth ? "black" : "white",
                      color: selectedMonth ? "white" : "black",
                    }}
                  >
                    Monthly
                  </Button>
                </div> */}
              </div>
            </div>
          {
            shiftsVisible ? (
              // <div className="col-12">
              //   {loading && <Skeleton />}
              //   {!loading && (
              //     <div className="table-responsive">
              //       <div className="card">
              //       {permissions.indexOf("export-product") !== -1 &&
              //           !isTablet && (
              //             <div className=" absolute right-6 items-center justify-center top-3 flex gap-2">
              //               <small>Export</small>
              //               <ReactHTMLTableToExcel
              //                 id="test-table-xls-button"
              //                 className="btn btn-sm btn-primary light flex items-center svg-btn shadow-none z-15"
              //                 table="table-to-xls"
              //                 filename="Barber-Product"
              //                 sheet="Product List"
              //                 buttonText={<FaFileExport />}
              //               />
              //             </div>
              //           )}
              //         <div className="card-body">
              //           <div className="table-responsive">
           <div className="col-12">
            <div className="table-responsive">
              <div className="card">
                <div className="overflow-hidden">
                {permissions.indexOf("export-product") !== -1 &&
                        !isTablet && (
                          <div className=" absolute right-6 items-center justify-center top-3 flex gap-2 mb-3">
                            <small>Export</small>
                            <ReactHTMLTableToExcel
                              id="test-table-xls-button"
                              className="btn btn-sm btn-primary light flex items-center svg-btn shadow-none z-15"
                              table="table-to-xls"
                              filename={`report ${tableDate} `}
                              sheet={tableDate}
                              buttonText={<FaFileExport />}
                            />
                          </div>
                        )} 
                        <div className="card-body mt-10">
                         <div className="table-responsive">
                  <table id="table-to-xls" className="display w-full">
      <thead>
        <tr>
          <th rowSpan="2">DATE</th>
          <th rowSpan="2">BARBER NAME</th>
          <th rowSpan="2">ENTRY</th>
          <th rowSpan="2">BARBER S</th>
          <th colSpan="4">SALES</th>
          <th colSpan="4">SERVICE</th>
          <th colSpan="3">DAILY ENTRY</th>
          <th colSpan="2">EXPENSES</th>
          <th rowSpan="2">CASH</th>
          <th rowSpan="2">G. TOTAL</th>
          <th rowSpan="2">DEPOSIT</th>
        </tr>
        <tr>
          <th>ITEM</th>
          <th>QTY</th>
          <th>RATE</th>
          <th>AMOUNT</th>
          <th>ITEM</th>
          <th>QTY</th>
          <th>RATE</th>
          <th>AMOUNT</th>
          <th>TOTAL</th>
          <th>MOMO</th>
          <th>POS</th>
          <th>Description</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {/* Morning Section */}
        <tr className="section-header">
          <td rowSpan={parseInt((tableArray.length+3)*2)}>{tableDate}</td>
          <td colSpan="2"></td>
          <td rowSpan={parseInt((tableArray.length+1))}>{mEmplTotal}</td>
          <td colSpan="8">MORNING : {shiftUserData[0]?.lastname}</td>
          <td rowSpan={parseInt((tableArray.length+2))}>{mDailyEntryTotal}</td>
          <td colSpan="4"></td>
          <td rowSpan={parseInt((tableArray.length+2))}>{mCashTotal}</td>
          <td rowSpan={parseInt((tableArray.length+3)*2)} className="total-cell">{grandTotal}</td>
          <td rowSpan={parseInt((tableArray.length+3)*2)} className="total-cell">{deposited}</td>
        </tr>
       
         {/* {topEmpData.map((team,index)=>( */}
          {/* <></> */}
          {
          tableArray.map((service,index)=>(

                <tr className="morning">  
                      <td>{topMEmpData[index]?.lastname}</td>
                      <td>{topMEmpData[index]?.totalAmount}</td>
                   
                      <td>{topMProdData[index]?.productname}</td>
                      <td>{topMProdData[index]?.quantity}</td>
                      <td>{topMProdData[index]?.unitPrice}</td>
                      <td>{topMProdData[index]?.totalAmount}</td>
                      <td>{topMServiceData[index]?.servicename}</td>
                      <td>{topMServiceData[index]?.quantity}</td>
                      <td>{topMServiceData[index]?.unitPrice}</td>
                      <td>{topMServiceData[index]?.totalAmount}</td>
                     
                      <td>{topMmomoData[index]?.amoutPaid}</td>
                      <td>{topMposData[index]?.amoutPaid}</td>
                      <td>{topMExpData[index]?.name}</td>
                      <td>{topMExpData[index]?.totalAmount}</td>
                      
                      
                    </tr>
      ))}
        {/* More rows for the morning section */}
        <tr>
          <td className="observation" colSpan="2" rowSpan="2">Observation</td>
          <td className="observation" colSpan="3" rowSpan="2"></td>
          
          <td className="observation" colSpan="1">subtotal</td>
          <td className="observation" colSpan="1">{mproductsSubTotal}</td>
          <td className="observation" colSpan="2"></td>
          <td className="observation" colSpan="1">subtotal</td>
          <td className="observation" colSpan="1">{mservicesSubTotal}</td>
         
          <td className="observation" colSpan="1">{mMomoTotal}</td>
          <td className="observation" colSpan="1">{mPosTotal}</td>
          <td className="observation" colSpan="1">total</td>
          <td className="observation" colSpan="1">{mExpensesTotal}</td>
         
        </tr>
        

        {/* Evening Section */}
        <tr >
          <td colSpan="12"></td>
        </tr>
        <tr className="section-header">
        <td colSpan="2"></td>
          <td rowSpan={parseInt((tableArray.length+1))}>{eEmplTotal}</td>
          <td colSpan="8">EVENING : {shiftUserData[1]?.lastname}</td>
          <td rowSpan={parseInt((tableArray.length+2))}>{eDailyEntryTotal}</td>
          <td colSpan="4"></td>
          <td rowSpan={parseInt((tableArray.length+2))}>{eCashTotal}</td>
        </tr>
        {
          tableArray.map((service,index)=>(

                <tr className="evening">  
                      <td>{topEmpData[index]?.lastname}</td>
                      <td>{topEmpData[index]?.totalAmount}</td>
                      
                      <td>{topProdData[index]?.productname}</td>
                      <td>{topProdData[index]?.quantity}</td>
                      <td>{topProdData[index]?.unitPrice}</td>
                      <td>{topProdData[index]?.totalAmount}</td>
                      <td>{topServiceData[index]?.servicename}</td>
                      <td>{topServiceData[index]?.quantity}</td>
                      <td>{topServiceData[index]?.unitPrice}</td>
                      <td>{topServiceData[index]?.totalAmount}</td>
                    
                      <td>{topEmomoData[index]?.amoutPaid}</td>
                      <td>{topEposData[index]?.amoutPaid}</td>
                      <td className="evening-expense">{topExpData[index]?.name}</td>
                      <td>{topExpData[index]?.totalAmount}</td>
                      
                      
                    </tr>
      ))}
        {/* More rows for the evening section */}
        <tr>
          <td className="observation" colSpan="2" rowSpan="2">Observation</td>
          <td className="observation" colSpan="3" rowSpan="2"></td>
          <td className="observation" colSpan="1">subtotal</td>
          <td className="observation" colSpan="1">{eproductsSubTotal}</td>
          <td className="observation" colSpan="2"></td>
          <td className="observation" colSpan="1">subtotal</td>
        
          <td className="observation" colSpan="1">{eservicesSubTotal}</td>
          <td className="observation" colSpan="1">{eMomoTotal}</td>
          <td className="observation" colSpan="1">{ePosTotal}</td>
          <td className="observation" colSpan="1">total</td>
          <td className="observation" colSpan="1">{eExpensesTotal}</td>
         
        </tr>
      </tbody>
    </table>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
          
          )  : null}
          
         { rangeVisible ? (



<div className="col-12">
<div className="table-responsive">
  <div className="card">
    <div className="overflow-hidden">
    {permissions.indexOf("export-product") !== -1 &&
            !isTablet && (
              <div className=" absolute right-6 items-center justify-center top-3 flex gap-2 mb-3">
                <small>Export</small>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-sm btn-primary light flex items-center svg-btn shadow-none z-15"
                  table="table-to-xlss"
                  filename={`report ${tableDate2} `}
                  sheet={tableDate}
                  buttonText={<FaFileExport />}
                />
              </div>
            )} 
            <div className="card-body mt-10">
             <div className="table-responsive">
      <table id="table-to-xlss" className="display w-full">
      <thead>
        <tr>
          <th rowSpan="2">DATE</th>
          <th rowSpan="2">BARBER NAME</th>
          <th rowSpan="2">ENTRY</th>
          <th rowSpan="2">BARBER S</th>
          <th colSpan="4">SALES</th>
          <th colSpan="4">SERVICE</th>
          <th colSpan="2">DAILY ENTRY</th>
          <th colSpan="2">EXPENSES</th>
          {/* <th rowSpan="2">CASH</th> */}
          <th rowSpan="2">G. TOTAL</th>
          <th rowSpan="2">DEPOSIT</th>
        </tr>
        <tr>
          <th>ITEM</th>
          <th>QTY</th>
          <th>RATE</th>
          <th>AMOUNT</th>
          <th>ITEM</th>
          <th>QTY</th>
          <th>RATE</th>
          <th>AMOUNT</th>
          {/* <th>TOTAL</th> */}
          <th>MOMO</th>
          <th>POS</th>
          <th>Description</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {/* Morning Section */}
        <tr className="section-header">
          <td rowSpan={parseInt((tableArray.length+3)*2)}>{tableDate2}</td>
          <td colSpan="2"></td>
          <td rowSpan={parseInt((tableArray.length+1))}>{emplTotal}</td>
          <td colSpan="8"></td>
          <td rowSpan={parseInt((tableArray.length+2))}>{momoTotal}</td>
          <td rowSpan={parseInt((tableArray.length+2))}>{posTotal}</td>
          <td colSpan="2"></td>
          {/* <td rowSpan={parseInt((tableArray.length+2))}>{mCashTotal}</td> */}
          <td rowSpan={parseInt((tableArray.length+3)*2)} className="total-cell">{allGrandTotal}</td>
          <td rowSpan={parseInt((tableArray.length+3)*2)} className="total-cell">{allDeposited}</td>
        </tr>
       
         {/* {topEmpData.map((team,index)=>( */}
          {/* <></> */}
          {
          tableArray.map((service,index)=>(

                <tr className="morning">  
                      <td>{empData[index]?.lastname}</td>
                      <td>{empData[index]?.totalAmount}</td>
                   
                      <td>{prodData[index]?.productname}</td>
                      <td>{prodData[index]?.quantity}</td>
                      <td>{prodData[index]?.unitPrice}</td>
                      <td>{prodData[index]?.totalAmount}</td>
                      <td>{serviceData[index]?.servicename}</td>
                      <td>{serviceData[index]?.quantity}</td>
                      <td>{serviceData[index]?.unitPrice}</td>
                      <td>{serviceData[index]?.totalAmount}</td>
                     
                      {/* <td>{momoData[index]?.amoutPaid}</td>
                      <td>{posData[index]?.amoutPaid}</td> */}
                      <td>{expData[index]?.name}</td>
                      <td>{expData[index]?.totalAmount}</td>
                      
                      
                    </tr>
      ))}
        {/* More rows for the morning section */}
        <tr>
          <td className="observation" colSpan="2" rowSpan="2">Observation</td>
          <td className="observation" colSpan="3" rowSpan="2"></td>
          
          <td className="observation" colSpan="1">subtotal</td>
          <td className="observation" colSpan="1">{productsSubTotal}</td>
          <td className="observation" colSpan="2"></td>
          <td className="observation" colSpan="1">subtotal</td>
          <td className="observation" colSpan="1">{servicesSubTotal}</td>
         
          {/* <td className="observation" colSpan="1">{momoTotal}</td>
          <td className="observation" colSpan="1">{posTotal}</td> */}
          <td className="observation" colSpan="1">total</td>
          <td className="observation" colSpan="1">{expensesTotal}</td>
         
        </tr>
        

        
       
      </tbody>
    </table>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
          ):null



          

          }
        </div>
      </div>
    </Dashboard>
  );
};

export default Report;
