import React from 'react'
import Nodata from '../../../Nodata'

const ClientProduct = () => {
  return (
    <div>
      <Nodata />
    </div>
  )
}

export default ClientProduct
