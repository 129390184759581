import React, { useState } from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { axiosRequest, refreshPage } from "../../api";
import LoadingButton from "../LoadingButton";
import Logo from "../../assets/images/14barber.png";
import { setUserSession } from "../utils/common";

const ForgetPassword = () => {
  let [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmite = (e) => {
    e.preventDefault();
    const url = "team/forgot/password";
    setLoading(true);
    axiosRequest
      .post(url, { email })
      .then((response) => {
        setEmail(" ");
        setLoading(false);
        const result = response.data;
        const { message } = result;
        toast.success(message);
        if (message === "Email Sent successfully 👍🏾") {
          setTimeout(() => {
            navigate("/");
          }, 1500);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === "ERR_BAD_REQUEST") {
          toast.error(error.response.data.message);
        } else {
          toast.info(error.message);
          setTimeout(() => {
            setLoading(false);
            refreshPage();
          }, 2000);
        }
      });
  };

  return (
    <>
      <ToastContainer />
       {/* =========================== End::  AskAdminPermissionModel =============================== */}

       <div className="min-h-screen bg-black flex">
      <div className="m-auto grid grid-cols-1 lg:grid-cols-2 gap-16  p-4">
        {/* Logo Section */}
        <div className="flex items-center justify-center lg:justify-start">
          <div className="text-center ml-">
           <img src={Logo} alt="Logo" className="mx-auto lg:mx-0 mb-4 w-30 h-10" />
          <h2 className="text-4xl text-white font-bold">Oops!</h2>
          <h2 className="text-4xl text-white font-bold">Forgot your password!</h2>
            
            {/* <h1 className="text-5xl text-white font-bold"></h1> */}
          </div>
        </div>
        
        {/* Form Section */}
        <div className="bg-white p-8 rounded-xl shadow-xl">
          <h2 className="text-2xl font-bold mb-2 text-center text-[#111]">Recover Password</h2>
          <p className="text-sm mb-8 text-center text-[#111]">Enter your email below to receive a password reset link</p>
          <form className="space-y-6" >
            <input 
              type="email" 
              placeholder="Email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-md text-[#111]"
              required 
            />
            
            <button 
              type="submit"
              disabled={loading}
              onClick={handleSubmite}
              className="w-full bg-black text-white py-2 rounded-md hover:bg-back transition duration-300"
            >
              {loading ? 'Processing...' : 'Send'}
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
    </>
  );
};

export default ForgetPassword;
