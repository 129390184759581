import { useSelector } from "react-redux";

export default function ClientDetails() {
  const { cart } = useSelector((state) => state);
  return (
    <>
      <div className="flex items-center justify-between mb-5">
        <div className="flex items-left text-left flex-col">
          <span className="text-gray-900"> Monchauffeur</span>
          <span className="text-gray-900">A&F Plaza, Floor 2 Door 16, KG 5 Rd, Kigali</span>
          <span className="text-gray-900">Kigali - Rwanda, monchauffeur.rw</span>
          <span className="text-gray-900">+250794415787</span>
          <span className="text-gray-900">
            Done By: {cart?.selected?.data?.user?.firstname}
          </span>
        </div>
        <div className="flex items-left text-right flex-col">
          <span className="text-gray-900">
            <strong>Client Name:</strong>
            {cart?.selected?.data?.client?.firstname}
          </span>
        </div>
      </div>
    </>
  );
}
